/** @jsx jsx */
import { jsx, Styled, Box } from "theme-ui"

import Head from "./head"
import Header from "./header"
import Main from "./main"
import Footer from "./footer"

import "katex/dist/katex.min.css"

export const Layout = ({ children, bg }) => {
  return (
    <Styled.root sx={{ bg }}>
      <Head />
      <Box
        sx={{
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Header />
        <Main>{children}</Main>
        <Footer />
      </Box>
    </Styled.root>
  )
}

export default Layout
